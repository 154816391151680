.company-basic {
  position: relative;
  height: 100%;
  padding: 0px 30px 30px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(42, 24, 60, 0.1);

  .description {
    margin-bottom: 0;
  }

  .img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    img {
      display: block;
      margin-top: 0;
      border-radius: 9999px;
      object-fit: contain;
    }
  }

  .header {
    margin-top: 30px;
    margin-bottom: 10px;

    p {
      margin: 0;
    }

    .title {
      margin-bottom: 8px;
      overflow: hidden;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: #4b4c53;
      text-align: center;
      text-overflow: ellipsis;

      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .subtitle {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      font-stretch: normal;
      line-height: 22px;
      color: #c4c4c4;
      text-align: center;
      letter-spacing: normal;
    }
  }

  .tags {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: #4b4c53;
    }

    .popover-tags {
      display: flex;
      flex-direction: row;
      margin-left: -10px;
    }
  }

  .content-body {
    min-height: 60px;
    padding: 0 10px;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    color: #1c1c1d;
    text-align: center;
    letter-spacing: -0.39px;

    p {
      margin: 0;
    }
  }

  .view-btn {
    margin-top: auto;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin-top: 24px;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: #4b4c53;
      text-align: center;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      transition: 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
