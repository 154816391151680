.stages-main {
  display: flex;
  padding: 0 30px 30px !important;
  margin-top: 66px;

  .stages-cards {
    padding-top: 0px !important;
    margin-left: 28px !important;
  }
}

.stage-page {
  a:hover {
    text-decoration: underline;
  }

  &-timezone-info {
    position: sticky;
    top: 57px;
    z-index: 100;
    padding: 10px 0;
    font-size: 15px;
    color: #797979;
    text-align: center;
    background: #f0f2f5;

    @media screen and (max-width: 600px) {
      padding: 5px 0;
      margin-top: 0;
      background: white;
    }

    svg {
      margin-right: 5px;
      color: #FAB650;
      vertical-align: middle;
    }

    span {
      font-style: 14px;
      font-weight: normal;
    }
  }

  .lists-wrapper {
    display: flex;
    margin: 0 60px;
    margin-top: 30px;

    .pill {
      display: inline-block;
      padding: 0 18px;
      font-size: 18px;
      font-weight: bold;
      line-height: 33px;
      color: #181a55;
      letter-spacing: -0.4px;
      border: 2px solid #181a55;
      border-radius: 9999px;

      &.most-viewed {
        border-color: @red-100;
      }
    }

    .list {
      flex: 1;

      &:first-child {
        margin-right: 45px;
      }

      .talks {
        display: flex;
        flex-wrap: wrap;

        .talk-wrapper {
          box-sizing: border-box;
          width: 50%;
          padding-right: 20px;
          margin-top: 28px;
        }
      }
    }
  }

  .stage-content-wrapper {
    height: 100%;
    padding: 10px 25px 20px;
    background: #ffffff;
    border: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .current-talk-info-wrapper {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;

    .current-talk-now {
      padding: 2px 10px 1px;
      margin-right: 10px;
      font-size: 13px;
      font-weight: bold;
      color: @white;
      text-transform: uppercase;
      background: #16a085;
      border-radius: 4px;
    }

    .no-talks-streaming {
      font-size: 15px;
      color: #797979;
    }

    .current-talk-info {
      &-time {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 400;
        color: rgb(163, 167, 178);
      }

      a {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: rgb(75, 76, 83);
      }

      .track {
        display: inline-block;
        line-height: 18px;
        letter-spacing: 0;
      }
    }
  }

  section {
    background: @white;
  }

  .current-talk {
    position: relative;
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    transition: 0.15s ease-in-out;

    &.opened {
      display: block;
    }

    .streaming-button-wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 12px;
      color: @white;

      span {
        font-weight: bold;
      }
    }

    &.closed {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0;
      padding-top: 56.25%;
      margin-bottom: 10px;
      background: rgb(75, 76, 83);

      .opens-in {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        padding: 0 5px;
        border-radius: 100px;
        transform: translate(0, -50%);

        span {
          text-align: center;

          &:first-child {
            font-size: 24px;
            font-weight: 700;
            color: rgb(255, 255, 255);
          }

          &:last-child {
            font-size: 24px;
            font-weight: 600;
            line-height: 23px;
            color: rgb(234, 108, 160);
          }
        }
      }
    }
  }

  .title-wrapper {
    a {
      font-weight: bold !important;
    }
  }

  .stage-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;

    .single-stage:nth-child(1) .stage-section-wrapper {
      background: rgba(141, 88, 255, 0.302);
    }

    .single-stage:nth-child(2) .stage-section-wrapper {
      background: rgba(249, 143, 97, 0.4);
    }

    .single-stage:nth-child(3) .stage-section-wrapper {
      background: rgb(207, 206, 226);
    }

    .single-stage:nth-child(1) .bottom-addon {
      height: 15px;
      background: rgba(141, 88, 255, 0.302);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .single-stage:nth-child(2) .bottom-addon {
      height: 15px;
      background: rgba(249, 143, 97, 0.4);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .single-stage:nth-child(3) .bottom-addon {
      height: 15px;
      background: rgb(207, 206, 226);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .stage-section-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      min-width: 300px;
      height: 100%;
      padding: 0 12px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .stage-section {
        width: 100%;
        overflow: hidden;

        h2 {
          padding: 20px 0;
          margin: 0;
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: 30px;
          color: rgb(35, 11, 52);
          text-align: center;
          text-transform: uppercase;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        .current-talk {
          position: relative;
          background-position: center;
          width: 100%;
          height: max-content;
          background-size: cover;
          transition: 0.15s ease-in-out;

          &.opened {
            display: block;

            .current-talk-preview {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 0;
              padding-top: 56.25%;
              margin-bottom: 10px;
              background-color: #cccccc;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
              border-radius: 10px;

              &:hover {
                .play-button-stream {
                  opacity: 1;
                }
              }


              .blur-filter {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
              }

              .streaming-button-wrapper {
                position: absolute;
                top: 15px;
                left: 0;
                color: @white;

                &-dot {
                  width: 10px;
                  height: 10px;
                  background: white;
                  border-radius: 50%;
                }

                &-text {
                  padding: 5px 10px;
                  font-size: 15px;
                  font-weight: 700;
                  background: #e91916;
                  border-radius: 10px;
                }
              }

              .play-button-stream {
                width: 50px;
                height: 50px;
                background: transparent;
                opacity: 0.8;
                transition: opacity 0.05s ease-in-out;

                svg {
                  width: 100%;
                  height: 100%;

                  path {
                    fill: #ffffff;
                  }
                }
              }
            }
          }

          .streaming-button-wrapper {
            position: absolute;
            top: 10px;
            left: 10px;
            font-size: 12px;
            color: @white;

            span {
              margin-left: 5px;
              font-weight: bold;
            }
          }
        }

        .title-wrapper {
          margin: 10px 0;
          font-size: 14px;
          font-weight: bold;
          color: @red-100;

          a {
            font-size: 16px;
            font-style: normal;
            font-weight: bold;
            line-height: 20px;

            color: #4b4c53;
          }

          .title-button {
            height: 24px;
            padding: 0 10px;
          }
        }

        .track {
          display: inline-block;
          height: auto;
          padding: 0 6px;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 2px;
        }

        .next-stage-container {
          section {
            padding-top: 25px;
            border-top: solid 1px rgb(215, 218, 226);
            border-bottom: solid 1px rgb(215, 218, 226);

            .no-upcoming-talks {
              margin: 10px 0;
            }
          }

          .talks-wrapper {
            display: flex;

            &-head {
              display: flex;
              padding: 2px 0 5px;
              font-size: 13px;
              font-weight: 700;
              color: #b2bec3;
              text-transform: uppercase;
              border-bottom: 1px solid #ecedee;

              &-time {
                width: 100%;
              }

              &-title {
                display: flex;
                flex: 1;
                justify-content: space-between;
              }
            }

            &-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-top: 25px;

              .horizontal-line {
                position: absolute;
                top: 68px;
                left: 15px;
                width: 1px;
                height: calc(100% - 137px);
                background: #e8ebee;
              }

              .talk-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 35px;

                &-content {
                  display: flex;
                  flex: 1;
                  align-items: center;
                  min-height: 91px;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: normal;
                  line-height: 20px;
                  color: #4b4c53;

                  &-info {
                    a {
                      font-size: 16px;
                      font-style: normal;
                      font-weight: normal;
                      line-height: 20px;
                      color: #4b4c53;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }

          .view-more {
            display: flex;
            align-items: center;
            margin-top: 17px;

            a {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 800;
              line-height: 20px;
              color: @standout;
              text-decoration: none;
              opacity: 1;
              transition: 0.15s ease-in-out;

              &:hover {
                opacity: 0.8;
              }

              svg {
                margin-right: 5px;
              }

              span {
                padding-top: 4px;
              }
            }
          }
        }

        .talk-wrapper {
          text-decoration: none;

          .content {
            flex: 1;
            margin-left: 15px;
          }

          .text {
            display: flex;
            font-size: 16px;

            span {
              color: #6d7074;
            }

            a {
              margin-left: 5px;
              color: #1c1c1d;
            }
          }
        }
      }

    }
  }

  .stage-talk-item {
    display: flex;
  }

  .upcoming-talk-title {
    max-height: 44px;
    overflow: hidden;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px;
    color: #4b4c53;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
  }

  .talk-wrapper-time {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .notification-wrapper {
    display: flex;
    align-items: center;
    min-width: 25px;
    margin-left: auto;

    img {
      margin-right: 0;
      border-radius: 0;
      fill: #999999;
      object-fit: none;

      path {
        background-color: #000000;
        fill: #000000;
      }
    }

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 135.1%;
      color: #c4c4c4;
    }

    svg g {
      fill: #cfcfdb;
    }

    &.active-notification {
      svg {
        display: flex;
        color: @standout;
      }
    }

    &:hover {
      cursor: pointer;

      g {
        fill: @red-100;
      }
    }
  }
}

.stage-up-next-icon {
  background: @btn-background !important;
  color: @btn-color !important;
}

.replays-ant-row {
  .talk-content {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
  }
}

.talk-item-container {
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  overflow: hidden;
  background: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 5px 16px 0px rgba(42, 24, 60, 0.1);
  height: 100%;

  .talk-item {
    .talk-content-details {
      padding: 20px !important;
    }

    .talk-content {
      &-icon-play {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        height: 100%;
        box-shadow: inset 0 0 250px 0 #242525;
        transition: 0.2s;

        &:hover {
          background-color: #dcdcdc7d;
        }
      }
    }

    h3 {
      box-sizing: unset;
      display: box;
      margin-top: 10px;
      overflow: hidden;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: rgba(75, 76, 83, 1);
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      box-orient: vertical;
      padding: 5px 5px 0 10px;
    }

    .ant-tag {
      margin: 0 5px 10px 10px;
    }

    h3 {
      padding: 5px 5px 0 10px;
    }

    span {
      margin: 0 5px 10px 10px;
    }
  }
}
