.schedule {
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  .ant-table-wrapper {
    border: none;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: transparent !important;
  }

  .ant-table-container {
    border-color: transparent !important;
  }

  .ant-cell-no-right-border {
    border-right: none !important;
  }

  th.ant-cell-border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  td.ant-cell-border-top-left-radius {
    border-left: 1px solid rgb(215, 218, 226) !important;
  }

  th.ant-cell-border-top-left-radius {
    border-left: 1px solid rgb(215, 218, 226) !important;
    border-top-left-radius: 10px !important;
  }

  .ant-table-content {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 10px;
      background-color: rgba(15, 34, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 50px;
    }
  }

  .ant-table-tbody {
    position: relative;
    height: 100%;
    background: url(../../../images/diagonal-stripes-10.jpg) no-repeat;
    background-size: cover;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-cell-event-not-ended {
    background: rgb(255, 255, 255);

    .agenda-item-content-added-to-my-schedule {
      color: #32beea !important;
    }
  }

  .ant-cell-stage-break {
    background-color: rgba(253, 248, 111, 0.1);
  }

  .ant-table-thead > tr > th {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;

    color: #4b4c53;
    text-align: center;

    background: rgba(65, 39, 255, 0.05);
    background-color: transparent;
    border: none;
    border-right: 1px solid #d7dae2;
    border-bottom: 1px solid #d7dae2;

    &::after {
      background: @standout;
    }

    &:first-child {
      font-size: 23px;
      color: rgb(85, 88, 173);
      text-align: center;
      background-color: @white;
      border: none;

      &::after {
        background: transparent;
      }
    }

    &:nth-child(2) {
      &::after {
        border-top-left-radius: 10px;
      }
    }

    &:nth-child(4) {
      &::after {
        border-top-right-radius: 10px;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  thead .ant-table-cell {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    padding: 0;
  }

  table {
    table-layout: fixed !important;
  }

  tbody .ant-table-cell {
    height: 100px;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    border-color: rgb(215, 218, 226);

    .agenda-table-time {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: rgb(196, 196, 196);
      background: rgb(255, 255, 255);
    }

    &:first-of-type {
      border: none;
    }

    &.ant-table-cell-fix-left-last {
      border: none;
    }

    .agenda-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: 100px;
      padding: 15px;
      margin: 0 auto;
      cursor: pointer;

      &.streaming {
        margin-bottom: 1px;
        outline: solid 1px #f9424b;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .agenda-item-img {
        margin-right: 5px;
        border-radius: 3px;

        .image-or-default {
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .agenda-add-to {
        position: relative;
        font-size: 10px;
        line-height: 13px;
        color: #32beea;

        &::before {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          font-size: 13px;
          font-weight: bold;
          line-height: 10px;
          color: #32beea;
          text-align: center;
          content: "+";
          border: 1px solid #32beea;
          border-radius: 3px;
        }
      }

      .agenda-item-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        .agenda-item-content-time {
          margin: 7px 0;

          h6 {
            margin-right: 10px;
            font-size: 12px;
            color: #8c8f98;
          }

          div {
            margin-top: 2px;
          }
        }

        &-calendar {
          button {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;
            font-size: 10px;
            font-style: normal;
            font-weight: normal;
            line-height: 13px;
            color: #32beea;
            cursor: pointer;
            background: none;
            border: none;
            outline: none;

            i {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 13px;
              height: 13px;
              border-radius: 3px;
            }

            span {
              margin-left: 5px;
            }
          }
        }

        &-position {
          margin-bottom: 5px;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          color: #1d0d43;
        }

        &-details {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        &-speaker {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 0;
          font-size: 10px;
          font-weight: 400;
          color: #1d0d43;

          span {
            margin-right: 7px;

            &::after {
              display: inline-block;
              margin-left: 2px;
              content: ",";
            }

            &:last-of-type {
              margin-right: 0;

              &::after {
                display: none;
              }
            }
          }
        }

        &-added-to-my-schedule {
          float: right;
          font-size: 0.75rem;
          color: #a0a4b9;

          svg {
            width: 1.1rem;
            vertical-align: middle;
          }
        }

        .agenda-item-content-title {
          display: box;
          margin: 0;
          overflow: hidden;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          line-height: 135.1%;
          color: #8c8f98;
          text-overflow: ellipsis;
          white-space: wrap;
          -webkit-line-clamp: 2;
          box-orient: vertical;
        }

        &-description {
          display: block;
          margin-top: -7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .stage-header {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      color: #4b4c53;
      text-transform: uppercase;
    }
  }

  .time-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 41px 50px 40px;
    margin-left: 65px;
    background-color: @white;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .dates {

      @media screen and (max-width: 600px) {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
      }

      .date {
        display: inline-flex;
        flex-direction: column;
        padding: 10px 35px !important;
        margin-right: 0px !important;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.1;
        color: #4b4c53;
        background-color: transparent;
        border: 1px solid #dadada;
        transition: 0.15s ease-in-out;

        span {
          padding-bottom: 5px;
          font-weight: bold;
          text-transform: uppercase;
        }

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        &-name {
          padding-bottom: 3px;
          margin-top: 8px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
        }

        &-day {
          padding-bottom: 3px;
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 700;
        }

        @media screen and (max-width: 1650px) {
          padding: 0 15px;
          margin-right: 10px;
        }

        @media screen and (max-width: 600px) {
          display: inline-block;
          padding: 0 10px;
          margin-right: 7px;
          font-size: 13px;
          line-height: 20px;
        }

        &.selected,
        &:hover {
          color: @btn-color;
          cursor: pointer;
          box-shadow: @btn-box-shadow;
          background: @btn-background;
        }
      }
    }

    .timezone-container {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      margin-right: 15px;
      font-size: 14px;
      color: @black;

      @media screen and (max-width: 900px) {
        justify-content: center;
      }

      .timezone-title {
        margin-right: 15px;
        font-weight: bold;
      }

      .timezone-switch {
        display: flex;
        align-items: center;

        .timezone-name {
          font-weight: 600;
          color: gray;
          white-space: nowrap;

          &:last-child {
            color: black;
          }
        }

        .conference-time-zone {
          display: block;
        }

        .local-time-zone {
          display: block;
        }

        @media screen and (max-width: 600px) {
          .conference-time-zone {
            word-break: break-word;
            white-space: normal;
          }

          .local-time-zone {
            word-break: break-word;
            white-space: normal;
          }
        }
      }
    }
  }
}


@-moz-document url-prefix("") {
  .ant-table-cell {
    height: 100% !important;
  }
}

.schedule-time-on-off {
  color: transparent !important; // TODO: comment out when we go live
  display: none !important; // TODO: comment out when we go live
}