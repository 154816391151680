.person-card {
  position: relative;
  display: flex;
  height: 100%;
  padding: 30px 20px 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
  transition: 0.15s ease-in-out;

  @media screen and (max-width: 600px) {
    padding: 10px 15px;
  }

  .card-header {
    display: flex;

    img {
      border-radius: 50%;
      object-fit: cover;
    }

    .user-info-header {
      flex: 1 1;
      align-self: center;
    }
  }

  .user-header {
    display: inline;
  }

  .tag-title {
    margin-right: 10px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #4b4c53;
  }

  .user-info {
    display: block;
    padding: 0 0 5px 10px;

    @media screen and (max-width: 600px) {
      padding: 5px 15px;
    }

    &-name {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 600;
      line-height: 14px;
      color: #4b4c53;
    }

    &-job {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #4b4c53;
    }

    &-country {
      display: inline-block;
      flex-direction: row;
      align-items: center;
      margin-top: 2px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #c4c4c4;

      svg {
        height: 20px;
        vertical-align: -20%;
      }
    }

    .subtitle {
      display: flex;
      margin-top: 3px;
      font-size: 13px;
      color: #4b4c53;
      letter-spacing: -0.19px;

      span {
        margin-right: 20px;
      }
    }
  }

  .tag-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .tags {
      align-items: center;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }
      }

      &-interested {
        .tag-title {
          display: block;
          white-space: pre;
        }
      }
    }
  }

  .contact-attendee {
    position: absolute;
    top: 12px;
    right: 14px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background: #e9ecff;
    border-radius: 100%;

    .ant-spin-spinning {
      height: 20px;
    }

    svg {
      &:hover {
        path {
          fill: @standout;
        }
      }
    }
  }
}
