.whats-on {
  flex: 1;
  padding: 0 20px;
  padding-bottom: 15px;
  margin-top: 25px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

  .whats-on-raffle {
    padding: 1rem 0;
    border-bottom: 1px solid #d7dae2;
  }

  > section {
    padding: 20px 0px;
    border-bottom: 1px solid #d7dae2;

    &:first-child {
      padding-bottom: 35px;
    }

    &:last-child {
      border: none;
    }

    @media screen and (max-width: 1400px) {
      padding: 20px 0;
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .view-more-button {
      position: relative;
      padding: 0;
      margin-left: auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: @standout;

      img {
        margin-right: 5px;
      }
    }
  }

  .title-wrapper {
    h4 {
      color: #4b4c53;
    }

    h5 {
      margin: 0 0 0 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 135.1%;
      color: #4b4c53;
    }
  }

  .icon-title {
    margin: 25px 0;
  }

  .up-next {
    padding-top: 0;
  }

  .title-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 28px;
    padding: 0;
    text-align: center;
    background: rgba(240, 30, 106, 0.8);
    border-color: transparent;
    box-shadow: none;

    &:hover {
      background: rgba(240, 30, 106, 0.8) !important;
    }
  }

  .up-next .title-button {
    background: rgba(50, 190, 234, 0.6);
    border-color: transparent;

    &:hover {
      background: rgba(50, 190, 234, 0.6) !important;
    }
  }

  .live-talks {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .live-talk {
      display: flex;

      .tag-wrapper {
        align-items: center;
        column-gap: 0.5rem;
      }

      .img-content-wrapper{
        display: flex;
      }

      h5 {
        display: box;
        max-height: 22px * 2;

        overflow: hidden;
        font-size: 15px;
        font-weight: bold;
        line-height: 22px;
        color: #4b4c53;
        text-overflow: ellipsis;
        word-wrap: break-word;
        box-orient: vertical;
        -webkit-line-clamp: 2;

        a {
          color: @black;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-time {
        display: inline-flex;
        margin-right: 5px;
        font-size: 16px;
      }

      &-clock {
        display: flex;
        align-self: flex-start;

        span {
          margin-right: 20px;
          font-size: 16px;
          line-height: "30px";
          color: "#C4C4C4";
        }

        img {
          margin-right: 0;
          border-radius: 0;
        }
      }

      .notification-wrapper {
        display: flex;
        align-items: center;
        min-width: 25px;
        margin-left: auto;

        img {
          margin-right: 0;
          border-radius: 0;
          fill: #999999;
          object-fit: none;

          path {
            background-color: #000000;
            fill: #000000;
          }
        }

        span {
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 135.1%;
          color: #c4c4c4;
        }

        svg g {
          fill: #cfcfdb;
        }

        &.active-notification {
          svg {
            display: flex;
            color: @standout;
          }
        }

        &:hover {
          cursor: pointer;

          g {
            fill: @red-100;
          }
        }
      }

      .content {
        flex: 1;
        margin-right: 5px;

        svg g {
          fill: #cfcfdb;
        }

        .tag-wrapper {
          align-items: center;

          .stage-time {
            margin-top: 5px;
            display: flex;
            align-items: center;
          }

          .stage {
            font-size: 16;
            font-weight: 700;
            color: #4b4c53;
          }
        }
      }
    }
  }

  .view-more-button {
    padding-top: 17px;
  }

  .border-bottom {
    border-bottom: 1px solid #d7dae2;
  }
}