.search-input {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  min-width: 130px;
  max-width: 400px;
  margin-top: 10px;
  margin-right: 20px;
  background-color: #fff;
  border-color: transparent;
  border-style: solid;
  border-radius: 10px;
  // margin-bottom: 30px;

  span {
    height: auto;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    outline: none;
    box-shadow: none;
  }

  .adw-cancel-icon {
    bottom: 0px !important;
    width: 24px !important;
    right: 10px !important;
    margin-left: auto;
  }

  .ant-input {
    z-index: 1;
    width: 100%;
    padding: 12px;
    background: transparent;
    border: 0;
    font-size: 14px;
    font-weight: 400;

    &:focus {
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #a3a7b2 !important;
    }
  }
}

.filter-container {
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px;
  margin: 0 0 0 20px;
  text-align: center;

  .adw-cancel-icon {
    right: 10px;
    bottom: 6px;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  .filter-right {
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .expertise-filter > span,
    .interes-filter > span,
    .country-filter > span,
    .adw-text-overflow {
      overflow: hidden;
      font-weight: 500;
      color: #a3a7b2;
    }

    .clear-filters-btn {
      margin-right: 20px;
      color: #686868;
      border: 1px solid gray;
      border-radius: 4px;

      @media screen and (max-width: 650px) {
        margin-bottom: 10px;
      }

      &:hover {
        color: #2e2e2e;
      }
    }

    .job-hiring-companies {
      display: flex;
      align-items: center;
      margin-right: 20px;

      h4 {
        padding-left: 10px;
        text-align: left;
      }
    }

    .filter-dropdown:last-child {
      @media screen and (max-width: 1199px) {
        margin-right: 20px;
      }
    }

    .filter-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 300px;
      height: 50px;
      padding: 14px 0px;
      margin-right: 23px;
      background-color: #ffffff;
      border: 0;
      border-radius: 10px;
      box-shadow: none;

      .ant-select-selector {
        margin-right: 0;
        border: 0;

        &:hover,
        &:focus {
          border: none;
          box-shadow: none;
          box-shadow: none;
        }
      }

      .ant-select-selection-item {
        padding: 14px 10px 14px 0;
        font-size: 14px;
        font-weight: 400;
        color: #a3a7b2;
        text-align: left;
      }

      .ant-select-selection-search-input {
        padding: 0;
        line-height: 22px;
      }

      span {
        display: block;
        overflow: visible;
        line-height: 22px;
      }

      @media screen and (max-width: 650px) {
        width: initial;
        margin-bottom: 10px;
      }
    }
  }
}

.checkbox-filters {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;
  margin: 15px 0 10px;
  text-align: center;

  .adw-cancel-icon {
    right: 10px;
    bottom: 6px;
  }

  .ant-dropdown {
    z-index: 6;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  .filter-right {
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    text-align: left;
    gap: 10px;

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .clear-filters-btn {
      margin-right: 20px;
      color: #686868;
      border: 1px solid gray;
      border-radius: 4px;

      @media screen and (max-width: 650px) {
        margin-bottom: 10px;
      }

      &:hover {
        color: #2e2e2e;
      }
    }

    .filters-dropdown {
      align-items: center;
      min-width: 350px;
      max-width: 350px;
      padding: 0.8rem;
      background: rgb(255, 255, 255);
      border-radius: 10px;
    }

    .filters-dropdown:first-child {
      margin-left: 0;
    }

    .ant-input-affix-wrapper {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .ant-collapse {
      display: flex;
    }

    .ant-dropdown-menu-item {
      border-radius: 10px;
    }

    .ant-collapse-item {
      min-width: 300px;
      max-width: 350px;
      height: 3rem;
      margin-left: 1rem;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

      .ant-collapse-header {
        position: relative;
        padding-right: 2rem;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #a3a7b2;

        &::after,
        &::before {
          position: absolute;
          top: 55%;
          right: 0;
          width: 10px;
          height: 2px;
          margin-top: -2px;
          margin-right: 1rem;
          content: "";
          background-color: #4b4c53;
          border-radius: 20px;
        }

        &::after {
          transform: rotate(-35deg);
        }

        &::before {
          right: 7px;
          transform: rotate(35deg);
        }

        span[role="img"] {
          display: none;
        }
      }

      .ant-collapse-content {
        padding: 1rem;
        margin-top: -10px;
        border-top: none;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        &-box {
          padding: 20px 0;
          border-top: 1px solid #d9d9d9;
        }
      }

      &-active {
        .ant-collapse-header {
          &::after {
            transform: rotate(35deg);
          }

          &::before {
            right: 7px;
            transform: rotate(-35deg);
          }
        }
      }
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}

.filters-dropdown-content {
  z-index: 6;
  min-width: 350px;
  max-width: 350px;

  ul {
    max-height: 400px;
    padding: 1rem;
    padding-top: 20px;
    margin-top: -20px;
    overflow-y: scroll;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    label {
      word-break: break-word;
      white-space: normal;
    }
  }
}
