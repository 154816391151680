.admin-page {
  padding: 20px;

  .segmented-control {
    justify-content: space-between;
    overflow-x: auto;
    border-bottom: 0;

    span {
      padding: 0;
      margin-right: 25px;
      margin-left: 25px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .wrapper-lg {
    margin-top: 20px;

    .ant-card {

      &-body {
        padding: 0;
        padding-left: 1px;

        .ant-input-search {
          border-radius: 5px;
        }
      }
    }
  }
}

.ql-editor {
  height: 400px !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul {
  margin-bottom: 1em !important;
}

.ql-editor pre {
  margin-top: 0 !important;
  margin-bottom: 1em !important;
}

.ql-editor blockquote {
  margin: 0 0 1em !important;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin-bottom: 0.5em !important;
}

.ql-editor img {
  vertical-align: middle;
}

#perk-admin {
  table {
    table-layout: fixed !important;
  }
}

.ant-table-content {
  overflow-x: auto;
}

.ant-table-wrapper {
  border: 1px solid #e2ebee;
}

.ant-input-affix-wrapper {
  height: fit-content;
}

.select-all-container {
  margin: 20px 0;
  font-weight: 500;
}

.ant-checkbox-wrapper {
  display: flex;
  margin: 5px 0;
}

.ant-checkbox-inner {
  top: 1px;
}
