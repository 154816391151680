.lobby-page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 57px);
  background: @white;
  background: #f4f5f8;

  .cnt-wrapper {
    display: flex;
    flex: 1;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    color: #4b4c53;

    h5 {
      font-weight: 600;
    }

    &.up-next {
      button {
        &:hover {
          opacity: 1;
        }
      }
    }

    h4 {
      margin: 0 0 0 2px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #4b4c53;
      letter-spacing: 0;
      letter-spacing: 0em;
    }
  }

  .view-more-button {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: @red-100;
    transition: 0.15s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    svg {
      margin-right: 5px;
    }
  }

  .whats-on-wrapper,
  .extra-information-wrapper {
    padding: 15px 0;
  }

  .main-content-wrapper {
    .main-content-section {
      padding: 20px 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .extra-information-wrapper {
      display: none;
    }

    .whats-on-wrapper {
      order: 1;
    }

    .main-content-wrapper {
      order: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .whats-on-wrapper {
      display: none;
    }
  }
}

.ant-carousel .slick-dots {
  display: none !important;
}

.intro-modal {
  transition: 0.15s ease-in-out;
  transform: scale(0.75) translate(0%, -10%);

  .footer-container {
    display: flex;
    width: 100%;
  }

  .ant-modal-content {
    overflow: hidden;
  }

  .ant-modal-close-x {
    width: 36px;
    height: 36px;
    margin-top: 10px;
    margin-right: 10px;
    line-height: 37px;
    background-color: rgb(255, 255, 255);
    border-radius: 9999px;
  }

  .ant-modal-footer {
    display: flex;
    padding: 0;
    background: #f7f8ff;

    .prev-button {
      img {
        transform: rotate(180deg);
      }
    }

    .intro-btn,
    .intro-btn:hover {
      height: auto;
      padding: 0;
      margin: 24px;
      font-size: 26px;
      font-style: normal;
      font-weight: bold;
      line-height: 160%;
      color: #13174c !important;
      background: transparent !important;
      border: 0;
      border-radius: 0;
      box-shadow: none;

      img {
        margin-left: 11px;
      }
    }
  }
}

.carusel-container {
  .container-intro-modal {
    .slider-content {
      height: 442px;
      padding: 20px 40px 0;
      color: #13174c;

      h3 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
        text-align: left;
      }

      p {
        font-size: 30px;
        line-height: 160%;

        span {
          font-weight: bold;
          color: @standout;
        }
      }

      .build-profile-button {
        display: flex;
        justify-content: center;
        padding: 25px;
        font-size: 24px;
        font-weight: bold;
        line-height: 140%;
        color: #ffffff;
        text-align: center;
        background: linear-gradient(91deg, #DA295D -46.49%, rgba(218, 41, 93, 0) 93.99%), #74233B;
        box-shadow: 0px 10px 20px rgba(124, 35, 62, 0.4);
        border-radius: 2px;
      }

      .note {
        padding: 18px 30px 26px;
        background: linear-gradient(180deg, #ffffff 28.98%, #edeef6 131.24%);
        border: 1px solid #e7ecfd;
        box-shadow: inset 0 3px 0 #13174c;

        h5 {
          font-size: 16px;
          font-weight: bold;
          line-height: 140%;
        }

        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }

  span.anticon.anticon-arrow-right.slick-arrow.slick-next,
  span.anticon.anticon-arrow-left.slick-arrow.slick-prev {
    top: 10px;
  }
}

.main-highlights {
  display: flex;
  justify-content: center;

  .ant-card-cover {
    img {
      border-radius: 10px 10px 0 0;
    }
  }

  .ant-card {
    margin-right: 35px;
    border-radius: 10px;
    box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);

    &:last-child {
      margin: 0;
    }
  }

  .highlight-time {
    .ant-card-meta-title {
      margin-bottom: 20px;
      color: #a3a7b2;
    }
  }

  .highlight-wrapper {
    .ant-card-meta-description {
      padding: 5px 10px;
      color: #ffffff;
      border-radius: 16.5px;

      &.seo {
        color: #000000;
        background-color: #ff9612;
      }

      &.content_marketing {
        background-color: #712eff;
      }

      &.conversion_optimization {
        color: #000000;
        background-color: #ffc700;
      }
    }

    .ant-card-meta-description:nth-child(1) {
      background-color: #0072ff;
    }
  }
}
