.next-talk-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: @secondary;
  background: @primary;
  flex-wrap: wrap;
  gap: 5px;

  &:hover {
    color: @secondary;
  }

  h4 {
    display: box;
    margin-bottom: 0;
    overflow: hidden;
    font-size: 18px;
    font-weight: bold;
    box-orient: vertical;
    color: @secondary;
    -webkit-line-clamp: 1;

    span {
      margin-left: 7px;
      font-weight: normal;
    }
  }

  .next-talk-banner-countdown {
    display: flex;
    align-items: center;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }

    .next-talk-countdown-wrapper {
      display: flex;
      align-items: center;
      margin: 0 15px;

      @media screen and (max-width: 900px) {
        align-items: flex-start;
        margin-left: 0;
      }

      .countdown-fraction-block {
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
          flex-direction: column;
        }

        .number {
          font-size: 35px;
          font-weight: bold;
          line-height: 1.4;

          @media screen and (max-width: 900px) {
            font-size: 24px;
          }
        }

        .label {
          margin-left: 10px;
          font-size: 16px;

          @media screen and (max-width: 900px) {
            display: none;
          }
        }

        .label-mobile {
          display: none;
          margin-top: -8px;

          @media screen and (max-width: 900px) {
            display: block;
          }
        }
      }

      strong {
        margin: 0px 20px;
        font-size: 24px;
        font-weight: 700;

        @media screen and (max-width: 900px) {
          margin: -4px 5px 0px;
        }
      }
    }
  }

  .next-talk-banner-button {
    margin-left: 5px;

    @media screen and (max-width: 900px) {
      margin-left: auto;
    }

    button {
      width: 200px;
      height: 50px;
      font-size: 17px;
    }
  }
}
