.stage-dropdown {
  position: relative;
  margin-right: 20px;

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: #181a55;
  }

  .ant-select-arrow {
    opacity: 0;
  }

  @media screen and (max-width: 600px) {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-bottom: 10px;
    margin-left: 20px;

  }

  .prefix-icon {
    position: absolute;
    top: 17px;
    left: 38px;
    z-index: 1;
  }

  .ant-select-disabled.ant-select.ant-select-lg {
    width: 100%;
    height: initial;
    overflow: auto;
    font-weight: bold;
    color: #181a55;
    border-radius: 5px;

    @media screen and (max-width: 600px) {
      border-radius: 0;
    }

    &.light .ant-select-selector {
      color: #ffffff;
    }

    &.light svg path {
      fill: #ffffff;
    }

    .ant-select-selector:hover,
    .ant-select-selection-item:hover,
    &:hover {
      cursor: default;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;

      @media screen and (max-width: 1600px) {
        max-width: 250px;
        text-overflow: ellipsis;
      }

      @media screen and (max-width: 1200px) {
        max-width: 350px;
      }
    }

    .ant-select-selection-item {
      padding-left: 32px !important;
      font-size: 18px;
    }

    .ant-select-arrow {
      width: 16px;
      height: 16px;
      margin-top: -9px;

      svg path {
        fill: #181a55;
      }
    }
  }
}
