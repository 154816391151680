.segmented-control {
  display: flex;
  flex: 1;
  align-items: flex-end;
  font-size: 1rem;
  font-weight: bold;
  border-bottom: 1px solid #d8d6d6;

  span {
    margin-right: 50px;
    font-size: 16px;
    line-height: 21px;
    color: #a3a7b2;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    color: @standout;
    border-bottom: 5px solid @standout;
  }

  .noselect {
    margin-bottom: 5px;
  }

  svg {
    margin-right: 8px;
    vertical-align: bottom;
  }
}
