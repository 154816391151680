.auth-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 62px);
  margin-top: -69px;
  background: url(../../../images/awe/auth/auth-background.png) bottom / cover;

  .auth-btn {
    background: linear-gradient(-140deg, #97f9ff, #67fbcf, #97f9ff);
    box-shadow: rgba(1, 1, 1, 20%) 0px 10px 20px,
      rgba(103, 252, 208, 40%) 0px 15px 40px,
      rgba(255, 255, 255, 55%) 0px -1px 1px inset;
    border-radius: 8px;
    height: 50px;

    span {
      color: #010101 !important;
      font-weight: 500;
    }

    &:hover {
      background: linear-gradient(-140deg, #67fbcf, #67fbcf);
      span {
        color: #010101 !important;
      }
    }
  }

  .adworld-logo {
    padding-bottom: 20px;
    text-align: center;
  }

  &-form-container {
    z-index: 1;
    width: 550px;
    max-width: 95%;
    padding: 70px;
    margin: 25px 15px 50px 15px;
    border: none;
    position: relative;

    .auth-popup-bg {
      position: absolute;
      z-index: -1;
      height: calc(100% + 6px);
      width: calc(100% + 6px);
      top: -3px;
      bottom: 0;
      right: 0;
      left: -3px;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      z-index: 2;

      img {
        height: 60px;
      }

      .title {
        font-size: 23px;
        color: rgb(24, 26, 85);
        text-transform: uppercase;
        letter-spacing: 0.1em;

        strong {
          font-weight: 700;
        }
      }
    }

    .ant-form {
      &-item {
        padding: 0 25px;
        color: #fff;

        span {
          color: #fff;
        }

        a {
          color: #fff;
          text-decoration: underline;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.password-form-item {
          margin-bottom: 7px;
        }

        &.ant-form-item-has-error {
          .ant-form-item-control-input {
            .ant-input-affix-wrapper {
              background-color: #4E5255;
              border-color: #4E5255;
              border-radius: 10px;
            }
          }
        }

        .ant-form-item-label label {
          color: #fff;
        }

        &-control-input {
          .ant-input-affix-wrapper {
            padding: 8px 12px;
            border-width: 2px !important;
            border-radius: 10px;
            box-shadow: none;
            background-color: #4E5255;
            border-color: #4E5255;

            .ant-input-prefix {
              margin-right: 10px;

              svg {
                color: rgb(172, 172, 172);
              }
            }

            input {
              font-size: 15px;
              color: #fff;
              background-color: #4E5255;
            }
          }

          &-content {
            > input {
              padding: 8px 12px;
              font-size: 15px;
              border-color: rgb(233, 233, 233);
              border-width: 2px !important;
              border-radius: 5px;
              box-shadow: none;
            }
          }
        }

        .ant-form-item-explain {
          margin-bottom: 10px;
          color: #fff;
        }
      }

      hr {
        height: 1px;
        margin: 15px 0;
        background-color: rgb(224, 224, 224) !important;
        border: none;
      }

      .forgot-password {
        padding: 0 25px;
        margin-bottom: 14px;
        font-weight: 700;
        text-align: right;

        a {
          font-size: 15px;
          color: #fff;
          background-clip: text;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .didnt-receive-login {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background-clip: text;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
