.company-super {
  height: 100%;
  min-height: 680px;
  color: #ffffff;
  background: transparent;
  border: none;
  perspective: 1000px;

  .company-top-card {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 10px;
    box-shadow: 0px 5px 16px rgba(42, 24, 60, 0.1);
    transition: transform 0.6s;
    transform-style: preserve-3d;

    &.flipped {
      z-index: 2;
      transform: rotateY(180deg);

      .company-popover {
        display: none;
      }
    }
  }
}

.ant-tag {
  border: 0;
}

.company-top-front,
.company-top-back {
  padding: 0 30px 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(42, 24, 60, 0.1);
  backface-visibility: hidden;
}

.company-top-front {
  position: relative;
  z-index: 1;
  height: 100%;
  padding-bottom: 100px;
}

.company-top-back {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  transform: rotateY(180deg);

  .post-video-player iframe {
    width: 100%;
    max-width: 100%;
  }
}

.company-header-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 135px;
}

.company-image-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 135px;
  padding: 15px 0;
  margin-bottom: 10px;

  a {
    span {
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    }
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 110px;
  }
}

.company-content {
  text-align: center;

  .content-title {
    margin: 0;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

    a {
      font-weight: 700;
      line-height: 30px;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .content-subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .description {
    min-height: 60px;
    margin: 13px 0 19px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

.company-supports {
  ul {
    padding: 0;
    margin: 0;
    margin-top: 25px;
    columns: 1;
    columns: 1;
    columns: 1;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.7rem;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;

      .check-text {
        padding-left: 1rem;
        word-wrap: break-word;
        white-space: normal;
      }

      .check-icn {
        svg {
          width: 34px !important;
          height: 34px !important;
          background-color: #000000 !important;
          border-color: #000000 !important;
          fill: white;
        }
      }
    }
  }

  .chat-now {
    position: absolute;
    right: 30px;
    bottom: 30px;
    left: 30px;

    a {
      display: block;
      padding: 14px 0 13px;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: -0.54px;
      border: 2px solid #ffffff;
      transition: 0.2s;

      &:hover {
        color: #000000;
        background-color: #ffffff;
      }
    }
  }

  .company-video {
    display: flex;
    padding: 15px 0;
  }
}

.company-hiring {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;

  a {
    padding-bottom: 5px;
    color: inherit;
    border-bottom: 4px solid;
    border-bottom-color: inherit;
  }
}

.chat-now {
  position: absolute;
  right: 30px;
  bottom: 28px;
  left: 30px;

  a {
    display: block;
    padding: 14px 0 13px;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    color: #ffffff;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 10px;
    transition: 0.2s;

    &:hover {
      color: #000000;
      background-color: #ffffff;
    }
  }
}

.company-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  padding: 9px 24px 9px 12px;
  margin: auto;
  cursor: pointer;
  background: white;
  border-radius: 30px;

  &.inverted {
    background-color: #000000;
  }

  &:hover {
    opacity: 0.7;
  }

  .company-url {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-weight: 600;
    color: transparent;
    border: none;
    border-radius: 30px;
    transition: 0.2s;

    svg {
      height: 26px;
      font-size: 30px;
      color: black;
    }

    p {
      margin: 0;
      margin-left: 9px;
      font-size: 14px;
    }
  }

  .video-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    font-size: 16px;
    font-weight: 800;
    color: black;
  }
}

.company-popover {
  display: flex;
  flex-direction: column;

  > span {
    padding: 3px 7px;
    border-radius: 12px;
    transition: ease all 0.2s;
  }

  a {
    padding-bottom: 10px;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: #ffffff;
  }
}

.company-popover {
  margin-top: 15px;
  text-align: center;

  .text-box {
    display: inline-block;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
    background: rgba(35, 11, 52, 0.1);
    border-radius: 28px;

    .i-icon {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      padding-top: 1px;
      margin-left: 8px;
      font-weight: 700;
      line-height: 30px;
      color: #000000;
      cursor: pointer;
      background: #ffffff;
      border-radius: 100%;

      &:hover .interests-popup {
        display: block;
      }
    }
  }
}

.ant-popover-inner {
  border-radius: 5px;
}

&.inverted {
  color: #000000;

  .company-content {
    .content-subtitle {
      color: #000000;
    }
  }

  .company-supports {
    ul {
      li {
        .check-icn svg {
          color: #000000;
        }
      }
    }
  }

  .company-popover {
    a {
      padding-bottom: 10px;
      color: #000000;
    }
  }
}

.popover-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
