.user-answers {
  max-width: 700px;
  font-size: 16px;
  font-weight: 500;

  .answer-item {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 8px 8px 0;
    background-color: #f8f8f8;
    border-radius: 10px;

    .question-text {
      margin-right: 5px;
      font-weight: 700;
      color: @standout;
    }
  }
}

.attendee-profile-tag {
  background: @primary !important;
  color: @secondary !important;
}

.speaker-profile-tag {
  background: @primary !important;
  color: @secondary !important;
}

.attendee-profile-banner {
  // background: @profile-banner;
}

.speaker-profile-banner {
  background-color: @primary !important;
  color: @secondary !important;

  h2 span {
    color: @primary;
  }
}

.profile-chat-now-button {
  background: @secondary !important;

  svg:hover path {
    color: @secondary;
    fill: @standout;
  }
}

.profile-looking-for-work-btn {
  background: @primary !important;
  color: @secondary !important;
}

.speaker-profile-social-icons {
  display: flex;
  align-items: center;
  & svg path:first-of-type {
    fill: @tertiary;
  }
}
