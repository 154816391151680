.recommended-people-section {
  padding-top: 0;

  .section-view-all {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 225px;
      height: 33px;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 135.1%;
      color: #5175f4;
      text-align: center;
      cursor: pointer;
      background: none;
      background: #e9ecff;
      border: none;
      border-radius: 16.5px;
      outline: none;
    }
  }

  .person-list {
    margin: 0 0 12px;

    .person-item {
      padding: 15px 10px;
      margin-bottom: 0px;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #f8f8f8;
      }

      .speaker-information-container-image {
        object-fit: cover;
        align-self: center;
      }
    }

    .view-more-button {
      padding: 5px 23px;
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #5175f4;
      background: #e9ecff;
      border: none;
      border-radius: 16px;
    }
  }
}
